<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="dialogWidth"
    v-if="isDataLoadedFromServer"
    style="background-color: white"
  >
    <div class="bg-white poppins modal-root">
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">
          {{ actionType === "create" ? "Create" : "Edit" }} Return
        </h4>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>

      <div class="d-flex flex-column mx-5 modal-body">
        <!--begin::Body-->
        <v-stepper
          v-model="tab"
          non-linear
          outlined
          rounded="4"
          flat
          v-if="isStepperReady"
          @change="updateValidationStateOfSteps"
        >
          <v-stepper-header class="step-head-main">
            <v-stepper-step
              v-for="(item, i) in categories"
              :key="i"
              class="step-head"
              complete
              editable
              complete-icon="mdi-check-circle"
              edit-icon="mdi-check"
              error-icon="mdi-alert-circle"
              :step="i"
              :rules="[
                () => {
                  return item.valid;
                },
              ]"
            >
              {{ item.name }}
              <small v-if="!item.valid">Invalid</small>
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="0" key="Order Details" class="pt-2">
              <!-- internal order details -->
              <ExternalOrderDetails
                ref="externalOrderDetails"
                v-if="returnType === 'external'"
              ></ExternalOrderDetails>
              <!-- external order details -->
              <InternalOrderDetails
                ref="internalOrderDetails"
                v-if="returnType === 'iq'"
              ></InternalOrderDetails>
            </v-stepper-content>
            <v-stepper-content step="1" class="pt-2">
              <CollectionAddress
                ref="collectionAddress"
                :pageLoader="pageLoader"
              ></CollectionAddress>
            </v-stepper-content>
            <v-stepper-content step="2" class="pt-2">
              <SkuDatatable
                :setSku="setSkus"
                :skus="formData.skus"
                :skuItem="[]"
                :clientId="userId"
                :is_unit_price_required="get_is_unit_price_required"
              ></SkuDatatable>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
        <div class="d-flex align-center modal-action flex-wrap pt-4">
          <div
            :class="[
              'align-center d-flex col-12',
              returnType === 'external'
                ? 'justify-content-between'
                : 'justify-content-center',
            ]"
          >
            <template v-if="returnType === 'external'">
              <v-btn
                v-if="backState"
                class="order-1 order-sm-1"
                @click="nextStep('prev')"
                color="primary"
                dark
                ><v-icon>mdi-arrow-left</v-icon></v-btn
              >
              <div v-else class="order-1 order-sm-1"></div>
              <div
                class="order-2 order-sm-2 d-flex flex-wrap justify-content-center align-center"
                :class="{ invisible: nextState }"
              >
                <button
                  type="submit"
                  class="btn btn-danger px-5 py-3 mx-2 order-2 mt-1 mt-sm-0"
                  @click="toggleModal"
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-info px-5 py-3 mx-2 order-1 mt-1 mt-sm-0"
                  @click="submit"
                >
                  {{ actionType === "create" ? "Create" : "Update" }} Return
                </button>
              </div>
              <v-btn
                v-if="nextState"
                class="order-3 order-sm-3"
                @click="nextStep('next')"
                color="primary"
                dark
                ><v-icon>mdi-arrow-right</v-icon></v-btn
              >
              <div v-else class="order-3 order-sm-3"></div>
            </template>
            <template v-else>
              <button
                type="submit"
                class="btn btn-info px-5 py-3 mx-2 order-1 mt-1 mt-sm-0"
                @click="submit"
              >
                {{ actionType === "create" ? "Create" : "Update" }} Return
              </button>
            </template>
          </div>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import {
  UPDATE_TABLE_DATA,
  SET_SERVER_DATA,
  SET_CREATE_RETURN_TYPE,
  SET_EDIT_DATA,
  SET_SELECTED_CUSTOMER,
  SET_DESTINATION_ADDRESS_TYPE,
  SET_COLLECTION_ADDRESS_TYPE,
  SET_CUSTOM_DESTINATION_ADDRESS,
  SET_CUSTOM_COLLECTION_ADDRESS,
} from "@/core/services/store/returned.module";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import ExternalOrderDetails from "@/own/components/fulfillment/returned/external/ExternalOrderDetails.vue";
import InternalOrderDetails from "@/own/components/fulfillment/returned/internal/InternalOrderDetails.vue";
import CollectionAddress from "@/own/components/fulfillment/returned/external/CollectionAddress.vue";
import SkuDatatable from "@/own/components/fulfillment/returned/external/SkuDatatable.vue";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

import { validationMixin } from "vuelidate";
// import { required, requiredIf } from "vuelidate/lib/validators";

export default {
  name: "ActionDialog",
  components: {
    ExternalOrderDetails,
    InternalOrderDetails,
    CollectionAddress,
    SkuDatatable,
  },
  mixins: [validationMixin],
  validations() {
    return {};
  },
  data: () => ({
    dialog: false,
    tab: 0,
    formData: {
      skus: [],
    },
  }),
  methods: {
    toggleModal(id) {
      if (this.dialog) {
        this.clearFields();
      } else if (!this.dialog) {
        if (this.actionType === "edit") {
          this.loadEditDataFromServer(id);
        } else if (
          this.actionType === "create" &&
          this.returnType === "external"
        ) {
          setTimeout(() => {
            this.$refs.collectionAddress.setDeafultCollectionAddress();
          }, 50);
        }
      }
      this.dialog = !this.dialog;
    },
    async loadEditDataFromServer(id) {
      this.pageLoader(true);
      let data = { id: id.id };
      await ApiService.post(`/shipping/returns_management/edit`, data)
        .then((response) => {
          this.$store.commit(
            SET_SELECTED_CUSTOMER,
            response.data.return_management.customer_id
          );
          this.$store.commit(SET_SERVER_DATA, response.data);
          this.$store.commit(
            SET_CREATE_RETURN_TYPE,
            response.data.return_management.source_of_order
          );
          this.updateValidationStateOfSteps();

          const data = response.data.return_management;
          const orderDetails = {
            customer_id: data.customer_id,
            client_reference: data.client_reference,
            warehouse_id: data.warehouse_id,
            return_type_id: data.return_type_id,
            amount: data.amount,
            currency_id: data.currency_id,
            destination_carrier_address_id: data.destination_carrier_address_id,
            destination_customer_address_id:
              data.destination_customer_address_id,
            destination_warehouse_id: data.destination_warehouse_id,
            original_awb: data.original_awb,
            original_client_reference: data.original_client_reference,
            original_order_no: data.original_order_no,
            comment: data.comment,
            destination_customer_address_type: data.destination_address_type,
            collection_customer_address_type: data.collection_address_type,
            destination_address_type: data.destination_address_type,
            collection_carrier_address_id: data.collection_carrier_address_id,
            collection_customer_address_id: data.collection_customer_address_id,
            collection_warehouse_id: data.collection_warehouse_id,
            expected_collection_date: data.expected_collection_date,
            source_of_order: data.source_of_order,
          };

          const parcel = {
            number_of_boxes: data.parcel.number_of_boxes,
            total_weight: data.parcel.total_weight,
            length: data.parcel.length,
            height: data.parcel.height,
          };

          this.formData.skus = [...data.skus];

          const collectionAddress = { ...data.collection_address };

          this.$store.commit(SET_EDIT_DATA, {
            orderDetails,
            parcel,
            collectionAddress,
            skus: data.skus,
          });
          this.$store.commit(
            SET_COLLECTION_ADDRESS_TYPE,
            data.collection_address_type
          );
          this.$store.commit(
            SET_DESTINATION_ADDRESS_TYPE,
            data.destination_address_type
          );

          if (data.collection_address_type === 4) {
            this.$store.commit(
              SET_CUSTOM_COLLECTION_ADDRESS,
              data.collection_address
            );
          }
          if (data.destination_address_type === 4) {
            this.$store.commit(
              SET_CUSTOM_DESTINATION_ADDRESS,
              data.destination_address
            );
          }

          if (this.returnType === "external") {
            setTimeout(() => {
              this.$refs.externalOrderDetails.getEditData();
              this.$refs.collectionAddress.getEditData();
            }, 50);
          } else {
            setTimeout(() => {
              this.$refs.internalOrderDetails.getEditData();
            }, 50);
          }

          this.pageLoader(false);
        })
        .catch(() => {
          // this.dialog = false;
          this.pageLoader(false);
        });
    },
    nextStep(type) {
      this.updateValidationStateOfSteps();
      if (this.tab === 0) {
        this.$refs.externalOrderDetails.checkErrors();
      } else if (this.tab === 1) {
        this.$refs.collectionAddress.checkErrors();
      }
      if (type == "next") {
        if (this.tab < 3) {
          this.tab += 1;
        }
      } else {
        if (this.tab > 0) {
          this.tab -= 1;
        }
      }
    },
    updateValidationStateOfSteps() {
      // console.log(`working`);
      this.$v.$touch();
      this.categories.forEach((cat) => {
        cat.valid = !cat.rules(this);
        // console.log(cat);
      });
    },
    dateFormatter(date) {
      let d = date;
      return (
        [
          d.getFullYear(),
          d.getMonth() + 1 > 9 ? d.getMonth() + 1 : `0${d.getMonth() + 1}`,
          d.getDate() > 9 ? d.getDate() : `0${d.getDate()}`,
        ].join("-") +
        " " +
        [
          d.getHours() > 9 ? d.getHours() : `0${d.getHours()}`,
          d.getMinutes() > 9 ? d.getMinutes() : `0${d.getMinutes()}`,
          d.getSeconds() > 9 ? d.getSeconds() : `0${d.getSeconds()}`,
        ].join(":")
      );
    },
    submitExternal() {
      const url = {
        create: "/shipping/returns_management/store",
        edit: "/shipping/returns_management/update",
      };
      this.$refs.externalOrderDetails.checkErrors();
      this.$refs.collectionAddress.checkErrors();

      const isExternalOrderDetailsValid =
        this.$refs.externalOrderDetails.isFormValid;
      const isCollectionAddressValid = this.$refs.collectionAddress.isFormValid;
      const isSkuValid = this.formData.skus.length;

      if (
        isExternalOrderDetailsValid &&
        isCollectionAddressValid &&
        isSkuValid
      ) {
        this.pageLoader(true);
        const collection_address =
          this.$refs.collectionAddress.collection_address;

        const payload = {
          source_of_order: "external",
          ...this.$refs.externalOrderDetails.formData,
          expected_collection_date: this.$refs.externalOrderDetails.formData
            .expected_collection_date
            ? this.dateFormatter(
                this.$refs.externalOrderDetails.formData
                  .expected_collection_date
              )
            : null,
          parcel: {
            ...this.$refs.externalOrderDetails.parcel,
          },
          skus: this.formData.skus.map((item) => ({
            code: item.systemCode,
            sku_id: item.skuId,
            quantity: item.quantity,
            sku_code: item.sku_code,
            variant: item.variant,
          })),
          collection_address: {
            ...collection_address,
            state: collection_address?.state?.id,
            city: collection_address?.city?.id,
            area: collection_address?.area?.id,
          },
        };
        if (this.actionType === "edit") {
          payload.id = this.itemForAction.id;
        }
        if (payload.destination_customer_address_type === 2) {
          payload.destination_customer_address_id = payload.warehouse_id;
        }
        ApiService.post(url[this.actionType], payload)
          .then(() => {
            Swal.fire({
              title: `${this.actionType === "create" ? "Added" : "Updated"}`,
              text: `Return recorded`,
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
          })
          .then(() => {
            this.toggleModal();
            this.clearFields();
          })
          .then(() => {
            this.$store.dispatch(UPDATE_TABLE_DATA);
          })
          .finally(() => {
            this.pageLoader(false);
          });
      } else {
        return;
      }
    },
    submitInternal() {
      //check errors
      this.$refs.internalOrderDetails.checkErrors();
      const isOrderDetailsValid = this.$refs.internalOrderDetails.isInvalid;
      const formData = this.$refs.internalOrderDetails.formData;
      const orderDetails = this.$refs.internalOrderDetails.order_details;
      this.$refs.internalOrderDetails.types.destination_address_type;
      const url = {
        create: "/shipping/returns_management/store",
        edit: "/shipping/returns_management/update",
      };
      if (this.$store.getters.getRETURNSSelectedCollectionAddressType === 4) {
        const address = this.$store.getters.getRETURNSCustomCollectionAddress;
        let hasAllProperties = true;
        const requiredProperties = [
          "name",
          "country",
          "state",
          "city",
          "address",
          "phone",
        ];
        requiredProperties.forEach((property) => {
          if (
            !address.hasOwnProperty(property) ||
            address[property] === null ||
            address[property] === ""
          ) {
            hasAllProperties = false;
            return;
          }
        });
        if (!hasAllProperties) {
          Swal.fire({
            title: `Invalid Address`,
            html: `Custom collection address is not valid`,
            icon: "warning",
            showConfirmButton: true,
            // timer: 2500,
          });
          return;
        }
        formData.collection_address_type = 4;
        formData.collection_customer_address_id = null;
        formData.collection_warehouse_id = null;
        formData.collection_address = {
          ...address,
          state: address?.state?.id || address.state,
          city: address?.city?.id || address.city,
          area: address?.area?.id || address.area,
        };
      }
      if (this.$store.getters.getRETURNSSelectedDestinationAddressType === 4) {
        const address = this.$store.getters.getRETURNSCustomDestinationAddress;
        let hasAllProperties = true;
        const requiredProperties = [
          "name",
          "country",
          "state",
          "city",
          "address",
          "phone",
        ];
        requiredProperties.forEach((property) => {
          if (
            !address.hasOwnProperty(property) ||
            address[property] === null ||
            address[property] === ""
          ) {
            hasAllProperties = false;
            return;
          }
        });
        if (!hasAllProperties) {
          Swal.fire({
            title: `Invalid Address`,
            html: `Custom destination address is not valid`,
            icon: "warning",
            showConfirmButton: true,
            // timer: 2500,
          });
          return;
        }
        formData.destination_address_type = 4;
        formData.destination_customer_address_id = null;
        formData.destination_warehouse_id = null;
        formData.destination_address = {
          ...address,
          state: address?.state?.id || address.state,
          city: address?.city?.id || address.city,
          area: address?.area?.id || address.area,
        };
      }
      if (!isOrderDetailsValid) {
        const payload = {
          source_of_order: "iq",
          customer_id: this.selectedCustomer,
          ...formData,
          orders: [...orderDetails],
        };
        if (this.actionType === "edit") {
          payload.id = this.itemForAction.id;
        }
        if (this.internalOrderSkus.length) {
          payload.orders[0].skus = this.internalOrderSkus;
        }
        this.$store.commit(SET_PAGE_LOADING, true);
        ApiService.post(url[this.actionType], payload)
          .then(({ data }) => {
            let response = "Record Updated";
            if (Array.isArray(data.messages)) {
              response = data?.messages
                .map((res) => `<div>${res}</div><br>`)
                .join("");
            }
            Swal.fire({
              title: `Response`,
              html: response,
              icon: "info",
              showConfirmButton: true,
              // timer: 2500,
            });
          })
          .then(() => {
            this.$store.dispatch(UPDATE_TABLE_DATA);
          })
          .then(() => {
            this.toggleModal();
            this.clearFields();
          })
          .finally(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          });
      }
    },
    submit() {
      if (this.returnType === "external") {
        this.submitExternal();
      } else if (this.returnType === "iq") {
        this.submitInternal();
      }
    },
    setSkus(item) {
      this.formData.skus = [...item];
    },
    pageLoader(val = true) {
      this.$store.commit(SET_PAGE_LOADING, val);
    },
    clearFields() {
      if (this.returnType === "external") {
        this.$refs.externalOrderDetails.clearFields();
      } else if (this.returnType === "iq") {
        this.$refs.internalOrderDetails.clearFields();
      }
      this.$refs.collectionAddress.clearFields();
      this.formData.skus = [];
      this.tab = 0;

      this.$store.commit(SET_CUSTOM_COLLECTION_ADDRESS, {});
      this.$store.commit(SET_CUSTOM_DESTINATION_ADDRESS, {});
      this.$store.commit(SET_COLLECTION_ADDRESS_TYPE, null);
      this.$store.commit(SET_DESTINATION_ADDRESS_TYPE, 2);
    },
  },
  computed: {
    categories: function () {
      const items = [
        {
          name: "Order Details",
          valid: true,
          rules: (el) => {
            try {
              return (
                el.$refs[`externalOrderDetails`][`$v`][`formData`].$invalid ||
                el.$refs[`externalOrderDetails`][`$v`][`parcel`].$invalid
              );
            } catch {
              return true;
            }
          },
        },
        {
          name: "Collection Address",
          valid: true,
          rules: (el) => {
            try {
              return el.$refs[`collectionAddress`][`$v`][`collection_address`]
                .$invalid;
            } catch {
              return true;
            }
          },
        },
        {
          name: "Items",
          valid: true,
          rules: () => {
            try {
              return !this.formData.skus.length;
            } catch {
              return true;
            }
          },
        },
      ];
      if (this.returnType === "external") {
        return items;
      } else {
        return [items[0]];
      }
    },
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
    isStepperReady() {
      try {
        return this.$v ? true : false;
      } catch (error) {
        return false;
      }
    },
    backState() {
      return this.tab !== 0;
    },
    nextState() {
      return this.tab !== 2;
    },
    returnType() {
      return this.$store.getters.getRETURNSCreateReturnType;
    },
    dialogWidth() {
      return this.returnType === "external" ? "1200px" : "1050px";
    },
    userId: function () {
      return this.$store.getters.currentUser.data.is_client
        ? this.$store.getters.currentUser.data.id
        : null;
    },
    serverData: function () {
      return this.$store.getters.getRETURNSServerData;
    },
    get_is_unit_price_required() {
      let data = null;
      try {
        data = this.serverData.countries.filter(
          (country) => country.index === this.shipping_address.country
        )[0].unit_price_required;
      } catch {
        data = null;
      }
      return data;
    },
    isClient() {
      return this.$store.getters.currentUser.data.is_client;
    },
    internalOrderSkus() {
      const skus = this.$store.getters.getRETURNSInternalOrdersSkus;
      if (skus) {
        return skus.filter((item) => item.valid);
      }
      return [];
    },
    selectedCustomer: function () {
      return this.$store.getters.getRETURNSSelectedCustomer;
    },
    actionType: function () {
      return this.$store.getters.getRETURNSActionType;
    },
    itemForAction: function () {
      return this.$store.getters.getItemForAction;
    },
  },
};
</script>
